import { ColumnId } from '../../../gql/graphql';

export enum ColumnVisibilityRule {
  CashOnly,
  CashDefault,
  Brexit,
  BrexitNlUkOnly,
}

export interface GridColumn {
  id: ColumnId;
  label: string;
  shortLabel?: string;
  printLabel?: string;
  default: boolean;
  visibility?: ColumnVisibilityRule;
  width: number;
}

export const gridColumnsById: Record<ColumnId, GridColumn> = {
  [ColumnId.PaymentStatus]: {
    label: 'Payment',
    printLabel: 'Paid',
    id: ColumnId.PaymentStatus,
    visibility: ColumnVisibilityRule.CashOnly,
    default: true,
    width: 65,
  },
  [ColumnId.Status]: {
    label: 'Status',
    id: ColumnId.Status,
    default: true,
    width: 210,
  },
  [ColumnId.QuayStatus]: {
    label: 'Quay status',
    id: ColumnId.QuayStatus,
    default: false,
    width: 180,
  },
  [ColumnId.SpecialProperty]: {
    label: 'Add-on icon',
    id: ColumnId.SpecialProperty,
    default: true,
    width: 40,
  },
  [ColumnId.EstimatedPickupTime]: {
    label: 'Pick up time',
    id: ColumnId.EstimatedPickupTime,
    default: false,
    width: 100,
  },
  [ColumnId.BookingNo]: {
    label: 'Booking',
    id: ColumnId.BookingNo,
    default: true,
    width: 85,
  },
  [ColumnId.RouteCode]: {
    label: 'Route',
    id: ColumnId.RouteCode,
    default: true,
    width: 85,
  },
  [ColumnId.DepartureDate]: {
    label: 'Date',
    id: ColumnId.DepartureDate,
    default: true,
    width: 100,
  },
  [ColumnId.DepartureTime]: {
    label: 'Time',
    id: ColumnId.DepartureTime,
    default: true,
    width: 75,
  },
  [ColumnId.VehicleRegNo]: {
    label: 'Vehicle reg',
    id: ColumnId.VehicleRegNo,
    default: true,
    width: 175,
  },
  [ColumnId.TrailerRegNo]: {
    label: 'Trailer reg',
    id: ColumnId.TrailerRegNo,
    default: true,
    width: 175,
  },
  [ColumnId.VehicleTypeCode]: {
    default: true,
    printLabel: 'VT',
    id: ColumnId.VehicleTypeCode,
    label: 'Vehicle type',
    width: 145,
  },
  [ColumnId.Length]: {
    label: 'Length',
    printLabel: 'L',
    id: ColumnId.Length,
    default: true,
    width: 64,
  },
  [ColumnId.Width]: {
    label: 'Width',
    printLabel: 'W',
    id: ColumnId.Width,
    default: true,
    width: 64,
  },
  [ColumnId.Height]: {
    label: 'Height',
    printLabel: 'H',
    id: ColumnId.Height,
    default: true,
    width: 64,
  },
  [ColumnId.NoOfDrivers]: {
    label: 'Drivers',
    shortLabel: 'Driv.',
    id: ColumnId.NoOfDrivers,
    default: true,
    width: 48,
  },
  [ColumnId.CustNo]: {
    label: 'Account number',
    id: ColumnId.CustNo,
    default: false,
    width: 120,
  },
  [ColumnId.CustIndex]: {
    label: 'Account',
    id: ColumnId.CustIndex,
    default: true,
    width: 120,
  },
  [ColumnId.CustomerReference]: {
    default: true,
    id: ColumnId.CustomerReference,
    label: 'Customer reference',
    width: 175,
  },
  [ColumnId.ImportReference]: {
    default: true,
    id: ColumnId.ImportReference,
    label: 'Import (to/from UK)',
    printLabel: 'Import',
    visibility: ColumnVisibilityRule.Brexit,
    width: 170,
  },
  [ColumnId.ExportReference]: {
    default: true,
    id: ColumnId.ExportReference,
    visibility: ColumnVisibilityRule.Brexit,
    label: 'Export (to/from UK)',
    printLabel: 'Export',
    width: 170,
  },
  [ColumnId.Temperature]: {
    label: 'Temperature onboard',
    shortLabel: 'Temperature',
    printLabel: 'Temp.',
    id: ColumnId.Temperature,
    default: true,
    width: 102,
  },
  [ColumnId.HazardousGoods]: {
    label: 'Hazardous goods',
    shortLabel: 'Haz. goods',
    id: ColumnId.HazardousGoods,
    default: true,
    width: 100,
  },
  [ColumnId.LoadingPreferenceCode]: {
    label: 'Loading preference',
    printLabel: 'Loading pref.',
    id: ColumnId.LoadingPreferenceCode,
    default: true,
    width: 160,
  },
  [ColumnId.LivestockTypeCode]: {
    label: 'Animals',
    id: ColumnId.LivestockTypeCode,
    default: false,
    width: 135,
  },
  [ColumnId.LoadingListMessage]: {
    label: 'Loading note',
    id: ColumnId.LoadingListMessage,
    default: false,
    width: 140,
  },
  [ColumnId.ETA]: {
    label: 'ETA/ATA',
    id: ColumnId.ETA,
    default: false,
    width: 85,
  },
  [ColumnId.CargoWeight]: {
    label: 'Cargo weight',
    printLabel: 'Weight',
    id: ColumnId.CargoWeight,
    visibility: ColumnVisibilityRule.CashDefault,
    default: false,
    width: 100,
  },
  [ColumnId.CustomsClassification]: {
    default: false,
    id: ColumnId.CustomsClassification,
    visibility: ColumnVisibilityRule.BrexitNlUkOnly,
    label: 'UK customs procedure (NL-UK)',
    printLabel: 'UK cust. proc.',
    width: 240,
  },
  [ColumnId.DateCreated]: {
    label: 'Created date/time',
    id: ColumnId.DateCreated,
    default: false,
    width: 136,
  },
  [ColumnId.CreatedBy]: {
    label: 'Created by',
    id: ColumnId.CreatedBy,
    default: false,
    width: 123,
  },
  [ColumnId.DateUpdated]: {
    label: 'Updated date/time',
    id: ColumnId.DateUpdated,
    default: false,
    width: 136,
  },
  [ColumnId.ModifiedBy]: {
    label: 'Updated by',
    id: ColumnId.ModifiedBy,
    default: false,
    width: 123,
  },
  [ColumnId.DaysOnQuay]: {
    label: 'Days on quay',
    id: ColumnId.DaysOnQuay,
    default: false,
    width: 105,
  },
  // CustomerCustomsStatus is replaced merged into import/export-reference
  [ColumnId.CustomerCustomsStatus]: {
    label: 'Customs info.',
    id: ColumnId.CustomerCustomsStatus,
    default: false,
    width: 100,
  },
  [ColumnId.SenderCountryCode]: {
    label: 'Sender country',
    id: ColumnId.SenderCountryCode,
    visibility: ColumnVisibilityRule.CashOnly,
    default: true,
    printLabel: 'S country',
    width: 120,
  },
  [ColumnId.ReceiverCountryCode]: {
    label: 'Receiver country',
    id: ColumnId.ReceiverCountryCode,
    visibility: ColumnVisibilityRule.CashOnly,
    printLabel: 'R country',
    default: true,
    width: 120,
  },
  [ColumnId.TradeWeight]: {
    label: 'Trade weight',
    id: ColumnId.TradeWeight,
    default: false,
    width: 100,
  },
};

export const getGridColumnDefaultWidth = (id: ColumnId): number => {
  return gridColumnsById[id].width;
};

export const getGridColumnLabel = (id: ColumnId): string => {
  return gridColumnsById[id].label;
};

export const getSearchColumnLabel = (id: ColumnId) => {
  if (id === ColumnId.CustomerReference) {
    return 'Customer ref.';
  } else {
    return getGridColumnLabel(id);
  }
};

export const getGridColumnDefaultChecked = (id: ColumnId): boolean => {
  return gridColumnsById[id].default;
};

export const gridColumns: GridColumn[] = [
  gridColumnsById[ColumnId.PaymentStatus],
  gridColumnsById[ColumnId.Status],
  gridColumnsById[ColumnId.SpecialProperty],
  gridColumnsById[ColumnId.BookingNo],
  gridColumnsById[ColumnId.RouteCode],
  gridColumnsById[ColumnId.DepartureDate],
  gridColumnsById[ColumnId.DepartureTime],
  gridColumnsById[ColumnId.VehicleRegNo],
  gridColumnsById[ColumnId.TrailerRegNo],
  gridColumnsById[ColumnId.VehicleTypeCode],
  gridColumnsById[ColumnId.Length],
  gridColumnsById[ColumnId.Width],
  gridColumnsById[ColumnId.Height],
  gridColumnsById[ColumnId.NoOfDrivers],
  gridColumnsById[ColumnId.CustomsClassification],
  gridColumnsById[ColumnId.ExportReference],
  gridColumnsById[ColumnId.ImportReference],
  gridColumnsById[ColumnId.CustIndex],
  gridColumnsById[ColumnId.CustomerReference],
  gridColumnsById[ColumnId.Temperature],
  gridColumnsById[ColumnId.HazardousGoods],
  gridColumnsById[ColumnId.LoadingPreferenceCode],
  gridColumnsById[ColumnId.LivestockTypeCode],
  gridColumnsById[ColumnId.LoadingListMessage],
  gridColumnsById[ColumnId.ETA],
  gridColumnsById[ColumnId.EstimatedPickupTime],
  gridColumnsById[ColumnId.CargoWeight],
  gridColumnsById[ColumnId.SenderCountryCode],
  gridColumnsById[ColumnId.ReceiverCountryCode],
  gridColumnsById[ColumnId.DateCreated],
  gridColumnsById[ColumnId.CreatedBy],
  gridColumnsById[ColumnId.DateUpdated],
  gridColumnsById[ColumnId.ModifiedBy],
  gridColumnsById[ColumnId.DaysOnQuay],
  gridColumnsById[ColumnId.TradeWeight],
];

export const getGridColumns = () => {
  return gridColumns;
};

export const gridColumnsOnQuay: GridColumn[] = [
  gridColumnsById[ColumnId.QuayStatus],
  gridColumnsById[ColumnId.SpecialProperty],
  gridColumnsById[ColumnId.EstimatedPickupTime],
  gridColumnsById[ColumnId.BookingNo],
  gridColumnsById[ColumnId.VehicleRegNo],
  gridColumnsById[ColumnId.RouteCode],
  gridColumnsById[ColumnId.DepartureDate],
  gridColumnsById[ColumnId.DepartureTime],
  gridColumnsById[ColumnId.ETA],
  gridColumnsById[ColumnId.VehicleTypeCode],
  gridColumnsById[ColumnId.CustomerReference],
  gridColumnsById[ColumnId.CargoWeight],
  gridColumnsById[ColumnId.DaysOnQuay],
  gridColumnsById[ColumnId.ExportReference],
  gridColumnsById[ColumnId.ImportReference],
];

const gridColumnsOnQuayWithAccount = [...gridColumnsOnQuay, gridColumnsById[ColumnId.CustIndex]];

export const getGridColumnsOnQuay = (displayCustomerColumn: boolean): GridColumn[] => {
  if (displayCustomerColumn) {
    return gridColumnsOnQuayWithAccount;
  }
  return gridColumnsOnQuay;
};
