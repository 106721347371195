import { animalDropdownNoOption } from '../../../../common/graphql/useAnimalTypesOptions';
import { customsClassificationDropdownEmptyOption } from '../../../../common/graphql/useCustomsClassificationsOptions';
import { loadingPreferenceDropdownNoOption } from '../../../../common/graphql/useLoadingPreferenceOptions';
import { formatRegistrationNumberString } from '../../../../common/utils/stringUtil';
import { ColumnId, UpdateBookingInput } from '../../../../gql/graphql';
import { BookingFormState } from '../../../../services/update-form/updateFormReducer';
import { getFieldsToShow, isVisible } from './detailsUtils';
import { transformUpdateBookingFormStateToMinimalBooking } from './minimalBooking';

export const transformToInput = (item: BookingFormState): UpdateBookingInput => {
  if (!item.sailing) {
    throw new Error('No sailing selected');
  }

  if (!item.vehicleType) {
    throw new Error('No vehicle type selected');
  }

  const validFields = getFieldsToShow(
    transformUpdateBookingFormStateToMinimalBooking(item),
    Boolean(item.customer?.isCashCustomer),
  );

  return {
    bookingNo: item.bookingNo || 0,
    bookingStatusCode: item.saveAsWaitList ? 'W' : 'A',
    cargoWeight: (isVisible(validFields, ColumnId.CargoWeight) && item.cargoWeight) || 0,
    custNo: item.customer?.custNo ?? 0,
    customerReference:
      (isVisible(validFields, ColumnId.CustomerReference) && item.customerReference) || '',
    customsClassificationCode:
      isVisible(validFields, ColumnId.CustomsClassification) &&
      item.customsClassification &&
      item.customsClassification !== customsClassificationDropdownEmptyOption.data
        ? item.customsClassification.value
        : '',
    exportReference: item.exportReference ?? '',
    importReference: item.importReference ?? '',
    hazardousGoods:
      (isVisible(validFields, ColumnId.HazardousGoods) && item.hazardousGoods) || false,
    height: (isVisible(validFields, ColumnId.Height) && item.height) || 0,
    length: (isVisible(validFields, ColumnId.Length) && item.length) || 0,
    livestockTypeCode:
      (isVisible(validFields, ColumnId.LivestockTypeCode) &&
        item.livestockType &&
        item.livestockType !== animalDropdownNoOption.data &&
        item.livestockType.value) ||
      '',
    loadingListMessage:
      (isVisible(validFields, ColumnId.LoadingListMessage) && item.loadingListMessage) || '',
    loadingPreferenceCode:
      (isVisible(validFields, ColumnId.LoadingPreferenceCode) &&
        item.loadingPreference &&
        item.loadingPreference !== loadingPreferenceDropdownNoOption.data &&
        item.loadingPreference.value) ||
      '',
    noOfAdults: 0,
    noOfChildren: 0,
    noOfDrivers: (isVisible(validFields, ColumnId.NoOfDrivers) && item.noOfDrivers) || 0,
    noOfInfants: 0,
    noOfPlugins: (isVisible(validFields, ColumnId.Temperature) && item.noOfPlugins) || 0,
    pluginTemperature:
      (isVisible(validFields, ColumnId.Temperature) && item.pluginTemperature) || '',
    sailingId: item.sailing.id,
    timestamp: item.timestamp || '',
    tradeWeight: item.tradeWeight,
    trailerRegNo: formatRegistrationNumberString(item.trailerRegNo ?? ''),
    vehicleRegNo: formatRegistrationNumberString(item.vehicleRegNo ?? ''),
    vehicleTypeCode: item.vehicleType.code,
    width: (isVisible(validFields, ColumnId.Width) && item.width) || 0,
    senderCountryCode:
      (isVisible(validFields, ColumnId.SenderCountryCode) &&
        item.senderCountry &&
        item.senderCountry.value) ||
      '',
    receiverCountryCode:
      (isVisible(validFields, ColumnId.ReceiverCountryCode) &&
        item.receiverCountry &&
        item.receiverCountry.value) ||
      '',
  };
};
