import { ColumnId, SortDirection } from '../../../../gql/graphql';

export const getDefaultSortDirection = (id: ColumnId): SortDirection => {
  switch (id) {
    case ColumnId.CreatedBy:
    case ColumnId.CustIndex:
    case ColumnId.CustomsClassification:
    case ColumnId.DateCreated:
    case ColumnId.DateUpdated:
    case ColumnId.DepartureDate:
    case ColumnId.DepartureTime:
    case ColumnId.ETA:
    case ColumnId.LoadingListMessage:
    case ColumnId.ModifiedBy:
    case ColumnId.RouteCode:
    case ColumnId.Status:
    case ColumnId.TrailerRegNo:
    case ColumnId.VehicleRegNo:
    case ColumnId.VehicleTypeCode:
    case ColumnId.CustomerCustomsStatus:
      return SortDirection.Ascending;
    default:
      return SortDirection.Descending;
  }
};
